import { FormEvent, useCallback, useMemo, useState } from 'react'
import { create, all, Unit, createUnit, isUnit, isUndefined, MathJsInstance } from 'mathjs'
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@mui/material'

import JSZip from 'jszip'
import LoginRequired from '../LoginRequired'
import InfoBox from '../Common/InfoBox'
import {
    GridGenerationAlgorithm,
    Shape2IsoXmlConfigFields,
    Shape2IsoXmlErrors,
    Shape2IsoXmlFields,
    Shape2IsoXmlInputMatchingFields,
    Shape2IsoXmlOutputFields
} from '../../orm/dto'
import { CheckboxRow, InputRow, InputTextRow, SelectRow, StyledTableCell, SwitcherRow, UnitCalculatorRow } from '../utils'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'


import { FilePicker } from '../Common/FilePicker'
import { transformShapeToIsoXml } from '../../orm/dev4ag'
import DDI from '../../consts/DDI.json'
import Shp2ISOXMLHelp from '../help/Shp2ISOXMLHelp'


const INIT_SHAPE_TO_ISOXML_FIELDS: Shape2IsoXmlFields = {
    geometry_source: undefined,
    isoxmlTemplate: undefined,
    config: {
        input: {
            matchings: [
                {
                    taskId: 'TSK1',
                    filenameInZip: '',
                    attribute: '',
                    DDI: 1,
                    deviceElementId: 'DET-1',
                    scale: 0.01,
                    offset: 0,
                    generateFieldBoundary: false,
                    taskName: '',
                    taskNameFromFile: false
                }
            ]
        },
        output: {
            isoxmlVersion: '4.2',
            gridCellHeight: 10,
            gridCellWidth: 10,
            fmisTitle: 'dev4Ag shp2ISOXML',
            fmisVersion: '1.1',
            terminal: undefined
        },
        gridGenerationAlgorithm: 'center'
    }
}

const GRID_GENERATION_ALGORITHMS = [
    { label: 'Canvas', value: 'canvas' },
    { label: 'Center', value: 'center' },
    { label: 'Center-VT', value: 'center-vt' },
    { label: 'Intersection', value: 'intersection' },
]

const ISOXML_VERSIONS = [
    { label: 'v4.2', value: '4.2' },
    { label: 'v3.2', value: '3.2' },
]

const DDI_ARRAY = DDI

export default function Shape2ISOXML() {
    const [geometry_source, setGeometrySource] = useState<File>()
    const [isoxmlTemplate, setIsoXmlTemplate] = useState<File>()
    const [basicConfig, setBasicConfig] = useState<Shape2IsoXmlConfigFields>({ gridGenerationAlgorithm: INIT_SHAPE_TO_ISOXML_FIELDS!.config!.gridGenerationAlgorithm! })
    const [matchings, setMatchings] = useState<Shape2IsoXmlInputMatchingFields[]>(INIT_SHAPE_TO_ISOXML_FIELDS.config.input?.matchings!)
    const [output, setOutput] = useState<Shape2IsoXmlOutputFields>(INIT_SHAPE_TO_ISOXML_FIELDS.config.output!)
    const [basicTask, setBasicTask] = useState<Shape2IsoXmlInputMatchingFields>(INIT_SHAPE_TO_ISOXML_FIELDS.config.input?.matchings[0]!)
    const [unit, setUnit] = useState<string>("l/ha");
    const [calcInactive, setCalcInactive] = useState<boolean>(true);

    const [errorDialog, setErrorDialog] = useState<boolean>()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({})

    const onOutputFieldChange = useCallback((event: FormEvent<HTMLInputElement> | SelectChangeEvent) => {
        const fieldName = (event.target as HTMLInputElement).name as keyof Shape2IsoXmlOutputFields
        const value = (event.target as HTMLInputElement).value
        setOutput({ ...output, [fieldName]: value })
    }, [output])

    const onMatchingFieldChange = useCallback((event: FormEvent<HTMLInputElement> | SelectChangeEvent, index: number) => {
        const target = event.target as HTMLInputElement
        const fieldName = target.name as keyof Shape2IsoXmlInputMatchingFields;
        const value = target.type !== 'checkbox' ?  target.value : target.checked;

        setMatchings(matchings.map((matching, i) => {
            if (i !== index) return matching
            const updated = { ...matching, [fieldName]: value }

            if (fieldName === 'taskNameFromFile' && value) {
                return { ...updated, taskName: '' }
            }

            return updated
        }));
    }, [matchings])

    const onBasicTaskChange = useCallback((event: FormEvent<HTMLInputElement> | SelectChangeEvent) => {
        const target = event.target as HTMLInputElement
        const fieldName = target.name as keyof Shape2IsoXmlInputMatchingFields;
        const value = target.type !== 'checkbox' ?  target.value : target.checked;

        if(fieldName === "DDI"){
            let fieldUnit="";
            let scale=1;
            let offset = 0;
            let isCalcInactive=false;
            switch( Number(value)){
                case 1:
                    fieldUnit ="l/ha";
                    scale=10000;
                    break;
                case 6:
                case 432:
                case 436:
                case 441:
                case 444:
                case 448:
                    fieldUnit = "kg/ha";
                    scale = 100;
                    break;
                case 16:
                case 51:
                case 56:
                case 61:
                    fieldUnit = "cm";
                    scale=0.1;
                    break;
                case 11:
                    fieldUnit="1/ha";
                    scale = 0.1;
                    break;
                case 21:
                    fieldUnit = "l/l";
                    scale=1;
                    break;
                case 26:
                    fieldUnit = "kg/kg";
                    scale=1;
                    break;
                default:
                    fieldUnit="";
                    scale=1;
                    isCalcInactive=true;
                    break;
                    
            }
            setUnit(fieldUnit);
            setBasicTask({ 
                ...basicTask, 
                DDI: Number(value),
            offset:offset,
            scale:scale });
            setCalcInactive(isCalcInactive);
    
        } else {
            setBasicTask({ ...basicTask, [fieldName]: value })
        }

        setMatchings(matchings.map((matching, i) => {
            const updated = { ...basicTask, ...matching, [fieldName]: value }

            if (fieldName === 'taskNameFromFile' && value) {
                return { ...updated, taskName: '' }
            }

            return updated
        }));
    }, [matchings, basicTask])

    const onAddMatching = useCallback(() => {
        setMatchings([
            ...matchings,
            {
                ...basicTask,
                taskId: `TSK${matchings.length + 1}`
            }
        ])
    }, [matchings, basicTask])

    const onDeleteMatching = useCallback((index: number) => {
        setMatchings(
            matchings
                .filter((_, i) => index !== i)
                .map((matching, i) => ({ ...matching, taskId: `TSK${i + 1}` }))
        )
    }, [matchings])

    const isValid = useMemo(() => {
        return !!geometry_source
    }, [geometry_source])

    const onSubmit = useCallback(async () => {
        setErrors({})
        if(!geometry_source){
            return;
        }
        const error = await transformShapeToIsoXml({
            geometry_source,
            isoxmlTemplate,
            config: {
                gridGenerationAlgorithm: basicConfig.gridGenerationAlgorithm,
                input: {
                    matchings
                },
                output
            }
        })
        if (error && error.message) {
            setErrorMessage(error.message)
            setErrorDialog(true)
        }
        if (error && error.messages) {
            const errors = (error as Shape2IsoXmlErrors).messages.reduce((acc, item) => ({
                ...acc,
                [item.details.param]: item.title,
            }), {})
            setErrors(errors)
        }
    }, [geometry_source, isoxmlTemplate, basicConfig, matchings, output])

    const onDialogClose = useCallback(() => {
        setErrorDialog(false)
    }, [])

    async function onLoadGeoShape(file: File | undefined): Promise<void> {
        if( file === undefined){
            setGeometrySource(undefined);
            return;
        }
        const zip = await JSZip.loadAsync(file);
        if(zip!==undefined && zip!==null){
            let updatedMatchings: Shape2IsoXmlInputMatchingFields[] = [];
            //Find all ShapeFiles:

            let fileNames = [
                ...Object.keys(zip.files).filter((name)=> name.endsWith(".shp")),
                ...Object.keys(zip.files).filter((name)=> name.endsWith(".geojson"))
            ]

            for( let entry of fileNames){
                let splitText = entry.replace("\\","/").split("/");
                let fileName = splitText[splitText.length-1].replace(".shp","");
                fileName = fileName.replace(".shp",".geojson")

                updatedMatchings.push(
                    {
                        ...basicTask,
                        taskId: `TSK${updatedMatchings.length + 1}`,
                        filenameInZip: fileName,
                        taskName: fileName
                    }
                )               
            }
            setMatchings(updatedMatchings);
        }
        


        setGeometrySource(file);


    }


    function tryToGenerateUnit(math:MathJsInstance,fromUnit:string, toUnit:string){
        try {
            math.createUnit(fromUnit,toUnit);
        } catch (e){
            //Nothing ToDo here, we just want to avoid crashes if a Unit is created twice
        }
    }


    function splitNumberAndUnit(input: string): { number: number, unit: string } {
        // This regex matches the optional number part, including integers or decimals,
        // followed by an optional space, and then the unit part.
        // It has two capturing groups: one for the number and one for the unit.
        //const regex = /(?:(\d+(?:\.\d+)?)\s*)?(.+)/;
        const regex = /^(\d*\.?\d*)\s*(.*)$/;
        const match = input.match(regex);
        console.log("Input: " + input + " Matches: " + JSON.stringify(match))
        if(match===null){
            return {
                number:1,
                unit:""
            }
        }

        let number=0;
        if(match[1]===""){
            number=1;
        } else {
            number = parseFloat(match[1]??"1")??1
        }

        return {
            number: number,
            unit: match[2]??""
        };
      }    


    function convertUnits(value: number, fromUnit: string, toUnit: string): Unit | string {
        let math = create(all);
        tryToGenerateUnit(math,"ha","1 hectare");
        tryToGenerateUnit(math,"ac","1 acre");
        tryToGenerateUnit(math,"l","1 litre");

        console.log("Conversion from " + fromUnit +" to " + toUnit);

        try {
            let fromUnits = fromUnit.split("/")
            let toUnits = toUnit.split("/")
            if( fromUnits.length !== toUnits.length){
                return "0"
            }

            let from:{number:number,unit:string} = splitNumberAndUnit(fromUnits[0]);
            let to:{number:number,unit:string} = splitNumberAndUnit(toUnits[0]);
            let factor=1;
            if (from.unit==="" || to.unit===""){
                factor = 1
            } else {
                factor = math.unit(1,from.unit).to(to.unit).toNumber(to.unit);
            }
            let resultValue = value * from.number/to.number * factor;
            console.log("First Factor: " + from.unit + " to "+ to.unit + " is " + factor + " Numbers "+ value+" from " + from.number + " to " + to.number+ " equals " + resultValue)
            if( fromUnits.length > 1){
                for(let index = 1; index<fromUnits.length;index++){
                    let from:{number:number,unit:string} = splitNumberAndUnit(fromUnits[index]);
                    let to:{number:number,unit:string} = splitNumberAndUnit(toUnits[index]);
                    let factor=1;
                    if (from.unit==="" || to.unit===""){
                        factor = 1
                    } else {
                        factor = math.unit(1,from.unit).to(to.unit).toNumber(to.unit);
                    }
                    resultValue = resultValue / (from.number/to.number * factor );
                    console.log("Factor for " + from.unit + " to " + to.unit + " is " + factor +  " Numbers "+ resultValue + " from " + from.number + " to " + to.number+ " equals " + resultValue)
                }
            }

            return resultValue.toString();
        } catch (error) {
          return `Error converting units: ${error}`;
        }
      }
      

    function onClickCalculate(fromUnit: string): void {
        let toUnit="";
        let bitResolution=1;
        switch(basicTask.DDI){
            case 1:
                toUnit="0.01 mm3/m2"
                bitResolution = 0.01
                break;
            case 6:
                toUnit="1 mg/m2"
                bitResolution= 1
                break
            case 11:
                toUnit ="0.001/m2"
                bitResolution=0.001
                break
        }
        
        let result = convertUnits(1, fromUnit,toUnit);
        setUnit(fromUnit);
        let updatedTask ={
            ...basicTask,
            scale: Number(result)
        }
        setBasicTask(updatedTask);
    }

    return (
        <LoginRequired>
            <Grid container wrap="nowrap" spacing={2} height={1}>
                <Grid item width='530px' sm='auto' container direction="column" spacing={2}>
                    <Grid item>
                        <Card variant="outlined">
                            <CardContent>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell>ZipFolder with Shape or GeoJSON</StyledTableCell>
                                            <StyledTableCell>
                                                <FilePicker onChange={(file) => onLoadGeoShape(file)} />
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell>ISOXML Template</StyledTableCell>
                                            <StyledTableCell>
                                                <FilePicker onChange={(file) => setIsoXmlTemplate(file)} />
                                            </StyledTableCell>
                                        </TableRow>
                                        <SelectRow 
                                            fields={basicConfig} 
                                            attr={'gridGenerationAlgorithm' as any}
                                            options={GRID_GENERATION_ALGORITHMS}
                                            title='Grid generation algorithm'

                                            onChange={(e)=>{
                                                setBasicConfig(
                                                    { 
                                                        gridGenerationAlgorithm: e.target.value as GridGenerationAlgorithm 
                                                    }
                                                )
                                            }}  />
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card variant="outlined">
                            <CardContent>
                                <Table size="small">
                                    <TableBody>
                                        <SelectRow 
                                            fields={output} 
                                            title='ISOXML Version' 
                                            attr={'isoxmlVersion' as any} 
                                            options={ ISOXML_VERSIONS}
                                            onChange={onOutputFieldChange}/>
                                        <InputRow fields={output} title='Grid Cell Width' attr={'gridCellWidth' as any} min={0} max={1000} onChange={onOutputFieldChange}/>
                                        <InputRow fields={output} title='Grid Cell Height' attr={'gridCellHeight' as any} min={0} max={1000} onChange={onOutputFieldChange}/>
                                        <InputTextRow fields={output} title='Fmis Title' attr={'fmisTitle' as any} onChange={onOutputFieldChange} disabled={true}/>
                                        <InputTextRow fields={output} title='Fmis Version' attr={'fmisVersion' as any} onChange={onOutputFieldChange} disabled={true}/>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item container justifyContent='center'>
                        <Button
                            type='button'
                            color='primary'
                            variant='contained'
                            disabled={!isValid}
                            onClick={() => onSubmit()}
                        >
                            Transform
                        </Button>
                    </Grid>
                </Grid>

                <Grid item mb={2} container wrap="nowrap" direction='column' spacing={2}>
                    <Grid item width='530px'>
                        <Card variant="outlined">
                            <CardContent>
                                <Table size="small">
                                    <TableBody>
                                        <InputTextRow fields={basicTask} title='Shape attribute' attr={'attribute' as any} onChange={(e) => onBasicTaskChange(e)} disabled={false} error={errors!['Config.Input.Matchings[0].ShapeAttribute']} />
                                        <SelectRow fields={basicTask} title='DDI' attr={'DDI' as any} options={DDI_ARRAY} onChange={ (e) => onBasicTaskChange(e)} />
                                        <TableRow><TableCell>DDI Number</TableCell><TableCell>{basicTask.DDI}</TableCell></TableRow>
                                        <InputRow fields={basicTask} title='Scale' attr={'scale' as any} min={0} max={1000} value={basicTask.scale} onChange={(e) => onBasicTaskChange(e)} />
                                        <InputRow fields={basicTask} title='Offset' attr={'offset' as any} min={-10000} max={10000} value={basicTask.offset}  onChange={(e) => onBasicTaskChange(e)} />
                                        <UnitCalculatorRow text={unit}  title='From Unit'  
                                            onClickCalculate={(e) => {
                                                return onClickCalculate(e)
                                            }} 
                                            disabled={calcInactive}
                                            error=''/>
                                        <CheckboxRow fields={basicTask} title='Generate field boundary' attr={'generateFieldBoundary' as any} onChange={(e) => onBasicTaskChange(e)}/>
                                        <SwitcherRow fields={basicTask} title='Task name from shapefile' attr={'taskNameFromFile' as any} onChange={(e) => onBasicTaskChange(e)}/>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>

                    {matchings.map((matching, index) => (
                        <Grid item width='530px' key={"matching_"+matching.taskId}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Table size="small">
                                        <TableBody>
                                            <InputTextRow fields={matching} title='FileName in Zip' attr={'filenameInZip' as any} onChange={(e) => onMatchingFieldChange(e, index)} disabled={false} error={errors!['Config.Input.Matchings[' + index + '].Shapefile']}/>
                                            <InputTextRow fields={matching} title='Task Id'   attr={'taskId' as any} onChange={(e) => onMatchingFieldChange(e, index)}    disabled={true} />
                                           <InputTextRow fields={matching} title='Task name' attr={'taskName' as any} onChange={(e) => onMatchingFieldChange(e, index)} disabled={matching.taskNameFromFile} error={errors!['Config.Input.Matchings[' + index + '].TaskName']} />
                                            <TableRow>
                                                <StyledTableCell colSpan={2}>
                                                    <Grid container justifyContent={matchings.length === index + 1 ? 'space-between' : 'flex-end'}>
                                                        {matchings.length === index + 1 && <Button
                                                            type='button'
                                                            color='primary'
                                                            startIcon={<AddCircleOutlineIcon />}
                                                            onClick={() => onAddMatching()}
                                                        >
                                                            Add Task
                                                        </Button>}
                                                        <Button type='button' color='error' startIcon={<DeleteIcon />} onClick={() => onDeleteMatching(index)} disabled={matchings.length === 1}>
                                                            Delete
                                                        </Button>
                                                    </Grid>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <InfoBox>
                    <Shp2ISOXMLHelp/>
                </InfoBox>
            </Grid>

            <Dialog open={!!errorDialog} onClose={onDialogClose}>
                <DialogTitle>Shape to ISOXML</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDialogClose}>Ok</Button>
                </DialogActions>
            </Dialog>
        </LoginRequired>
    )
}
