/* General */
export interface ResponseMessage {
    title: string
    code: string
    type: 'Error' | 'Warning' | 'Info'
    description: string
    details?: any
}

export interface ResponseWithMessages<ResponseType> {
    result?: ResponseType
    messages: ResponseMessage[]
}

/* User */
export interface User {
    name: string
}

/* PGN */
export interface PGNFields {
    priority: number
    extendedDataPage: boolean
    dataPage: boolean
    pduFormat: number
    pduSpecific: number
    sourceAddress: number
}

export interface PGNParseResult extends PGNFields {
    fullPGN: number
    destinationAddress: number | null
    groupExtension: number | null
    pgnType: 'PDU1' | 'PDU2'
    pgnString: string
}

/* ClientName */
export interface ClientNameFields {
    serialNo: number
    manufacturerCode: number
    ecuInstance: number
    functionInstance: number
    deviceClass: number
    deviceClassNo: number
    industryGroup: number
    selfConfigurable: boolean
    deviceClassInstance: number
    function: number
}

export interface Manufacturer{
    value: number
    manufacturer: string
    location: string
    date_created_or_last_modified: string
}

export interface ClientNameParseResult extends ClientNameFields {
    manufacturer: Manufacturer
    name: string
    deviceClassName: string
}

export interface LanguageParseResult{
    shorting: string
    language: string
    language_local: string
}

export interface LocalizationParseResult{
    languageCode: LanguageParseResult
    decimalFormat: string
    dateFormat: string
    timeFormat: string
    unitDistance: string
    unitMass: string
    unitGeneral: string
    unitArea: string
    unitVolume: string
    unitTemperature: string
    unitForce: string
    unitPressure: string
}

/* Device */
export interface DeviceDTO {
    clientNAME: string
    structureLabel: string
    localizationLabel: string
    designator?: string
    serialNumber?: string
    softwareVersion?: string
    clientNAMEParsed?: ClientNameParseResult
    localizationLabelParsed: LocalizationParseResult
}

export interface DeviceHierarchyRoot {
    designator?: string
    elements: DeviceHierarchyNode[]
}

export interface DeviceHierarchyNode {
    elementId: number
    elementType: string
    elementName?: string
    dpds: string[]
    dpts: string[]
    children: DeviceHierarchyNode[]
}

export interface DeviceProcessData {
    objectId: number
    ddi: number
    property: string[]
    triggerMethods: string[]
    designator: string
    unit: string

    ddiName?: string
    ddiHex: string
}

export interface DeviceProperty {
    objectId: number
    ddi: number
    designator: string
    rawValue: number
    unit: string

    ddiName?: string
    value?: number
    ddiHex: string
}

export interface DeviceElement {
    objectId: number
    type: string
    number: number
    parentObjectId: number
    designator?: string
    dpds: DeviceProcessData[]
    dpts: DeviceProperty[]
}

export interface DeviceAnalysisResult {
    hierarchy?: DeviceHierarchyRoot
    elements?: DeviceElement[]
    device?: DeviceDTO
}

export type GridGenerationAlgorithm = 'canvas' | 'center' | 'center-vt' | 'intersection'


export interface Shape2IsoXmlInputMatchingFields {
    taskId?: string
    filenameInZip?: string
    attribute?: string
    DDI?: number
    deviceElementId?: string
    scale?: number
    offset?: number
    generateFieldBoundary?: boolean
    taskName?: string
    taskNameFromFile?: boolean
}

export interface Shape2IsoXmlInputFields {
    matchings: Shape2IsoXmlInputMatchingFields[]
}

export interface Shape2IsoXmlOutputFields {
    isoxmlVersion?: string
    gridCellWidth?: number
    gridCellHeight?: number
    fmisTitle?: string
    fmisVersion?: string
    terminal?: string
}

export interface Shape2IsoXmlConfigFields {
    input?: Shape2IsoXmlInputFields
    output?: Shape2IsoXmlOutputFields
    gridGenerationAlgorithm: GridGenerationAlgorithm
}

export interface Shape2IsoXmlFields {
    geometry_source?: File
    isoxmlTemplate?: File
    config: Shape2IsoXmlConfigFields
}

export interface Shape2IsoXmlErrors {
    messages: Shape2IsoXmlError[]
}

export interface Shape2IsoXmlError {
    code: string
    details: {
        param: string
    }
    title: string
}

export interface ISOXMLCheckConfigFields {
    CheckProprietaryElements : boolean
}

export interface ISOXMLCheckFields {
    ISOXMLFile?: File
    Config: ISOXMLCheckConfigFields
}


export class ISOXMLCheckResult{
    messages: ResponseMessage[];    
    errorCount: number;
    warningCount: number;
    infoCount: number;


    constructor(_messages: ResponseMessage[]){
        this.messages = _messages;
        this.warningCount = _messages.filter( entry => entry.type === "Warning").length;
        this.errorCount = _messages.filter( entry => entry.type === "Error").length;
        this.infoCount = _messages.filter( entry => entry.type === "Info").length;
    };

    get isValid():boolean {
        return (this.warningCount === 0 && this.errorCount === 0);
    } 

}

export class ValueRange {
    public Min: number;
    public Max: number;
    public Default: number;
    public Major: boolean;
    public ChangePerSecond: number;
    private Factor: number;
    private Offset: number;

    constructor(min: number, max: number, defaultValue: number, major: boolean, changePerSecond: number, factor:number, offset:number) {
        this.Min = min
        this.Max = max
        this.Default = defaultValue
        this.Major = major
        this.ChangePerSecond = changePerSecond
        this.Factor = factor
        this.Offset = offset
    }

    updateDefault(value: number): boolean {
        this.Default = value;
        console.log("Update DefaultValue to " + value+". Valid: "+this.isRangeValid())
        return this.isRangeValid();
    }

    updateMin(min: number): boolean {
        this.Min = min;
        console.log("Update MinValue to " + min+". Valid: "+this.isRangeValid())
        return this.isRangeValid();
    }

    updateMax(max: number): boolean {
        this.Max = max;
        console.log("Update MaxValue to " + max +". Valid: "+this.isRangeValid())
        return this.isRangeValid();
    }

    isRangeValid(): boolean {
        return (this.Default >= this.Min && this.Default <= this.Max && this.Max > this.Min);
    }

    GetValue( input:number):number{
        return (input + this.Offset) / this.Factor
    }

    ConvertValue( input:number):number{
        return (input - this.Offset) * this.Factor
    }

    check(minValue: number, maxValue:number):boolean{
        minValue = this.ConvertValue(minValue)
        maxValue = this.ConvertValue(maxValue)

        if( minValue > this.Min || maxValue < this.Max || this.Default < minValue || this.Default > maxValue){
            return false;
        } else {
            return true;
        }
    }


}

export class ISOXMLFinisherConfig {
    public StartTime: string;
    public WorkingWidth: ValueRange;
    public WorkingSpeed: ValueRange;
    public Setpoint: ValueRange;

    constructor(startTime: Date, workingWidth: ValueRange, workingSpeed: ValueRange, setpoint: ValueRange) {
        this.StartTime = startTime.toISOString().split('.')[0]+"Z";
        this.WorkingWidth = workingWidth;
        this.WorkingSpeed = workingSpeed;
        this.Setpoint = setpoint;
    }
}

export class ISOXMLFinisherFields {
    public ISOXMLFile?: File;
    public DeviceDescriptions: string;
    public Config: ISOXMLFinisherConfig;

    constructor(deviceDescriptions: string, config: ISOXMLFinisherConfig, isoxmlFile?: File) {
        this.ISOXMLFile = isoxmlFile;
        this.DeviceDescriptions = deviceDescriptions;
        this.Config = config;
    }
}